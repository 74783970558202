<template>
<Page id="winners" title="Vinster">
    <Loader v-if="loading"></Loader>
    <div v-else>
        <div v-if="Prices.length > 0">

            <div class="my-3">
                <Alert variant="info" >Nedan visas hur prispengarna är fördelade över placeringarna.</Alert>
            </div>
            <b-row no-gutters class="tips-view-header">
                <b-col cols="8" class="text-left"> <span class="d-none d-sm-inline">Placering</span>
                    <span class="d-inline d-sm-none">Placering</span></b-col>
                <b-col cols="1" class="text-right"> <span class="d-none d-sm-inline">Poäng</span>
                    <span class="d-inline d-sm-none">P</span></b-col>
                <b-col cols="3" class="text-right"> <span class="d-none d-sm-inline">Prispengar</span>
                    <span class="d-inline d-sm-none">Pris</span></b-col>
            </b-row>
            <b-row no-gutters v-for="(winner, index) in winners" :key="index" class="align-items-center my-1 tips-winners-row">
                <b-col class="font-large font-weight-bold" cols="1">
                    {{winner.placement}}.
                </b-col>
                <b-col cols="7">
                    <div class="d-flex flex-row align-items-center groups-team" :id="'winners_user_row_' + winner.user.id">
                        <b-img class="gbook-avatar-small" rounded="circle" :src="useravatar(winner.user)"></b-img>
                        <div class="ml-0 ml-sm-2 text-truncate">
                            {{winner.user.username}}
                        </div>
                         <UserPopover :target="'winners_user_row_' + winner.user.id" :user="{ userid: winner.user.id, username: winner.user.username}"></UserPopover>
                    </div>
                </b-col>
                <b-col cols="1" class="text-right">
                    {{winner.user.totalpoints}}
                </b-col>
                <b-col cols="3" class="text-right font-large font-weight-bold">
                    {{currency(winner.price)}}
                </b-col>
            </b-row>
            <hr />
            <div class="mt-3">
                <b-row no-gutters>
                    <b-col>
                        <p>Placering avgörs på följande sätt</p>
                        <ul>
                            <li>Totalpoäng</li>
                            <li>Bonuspoäng</li>
                            <li>1-X-2-poäng</li>
                            <li>Resultat</li>
                            <li>Frågor</li>
                        </ul>
                    </b-col>
                    <b-col>
                        <p>Skulle det fortfarande vara lika kommer fördelningen att delas lika mellan placeringarna.
                            Om det ex är lika på plats 2 och 3 fördelas summan enligt (2 + 3) / 2 vilket medför att båda får lika mycket.</p>
                    </b-col>
                </b-row>

            </div>
        </div>
        <Alert v-else variant="info" >Just nu finns ingen information om vinster för tunreringen</Alert>
    </div>
</Page>
</template>

<script>
const Alert = () => import("@/components/miscellaneous/Alert");
const Page = () => import("@/components/common/Page");
const Loader = () => import("@/components/miscellaneous/Loader");

export default {
    name: "Users",
    components: {
        Alert,
        Page,
        Loader
    },
    data() {
        return {
            prices: [],
            winners: {}
        };
    },
    metaInfo: {
        title: "Vinnare",
        meta: [{
            name: "description",
            content: "Dessa tipsare hämtar hem vinster i turneringen!",
        }, ],
    },
    computed: {
        loading() {
            return this.$store.getters["winners/loading"];
        },
        Prices() {
            var data = this.$store.getters["winners/winners"];
            if (data)
                return data.prices;
            return [];
        },
        stats() {
            var data = this.$store.getters["winners/winners"];
            if (data) {
                // placera
                var placements = this.$functions.fixPlacements(
                    data.userstats,
                    "totalpoints",
                    "placement"
                );

                return placements;
            }
            return [];
        }
    },
    methods: {
        currency(value) {
            return this.$formatter.formatCurrency(value, 0);
        },
        CalculateWinnings() {
            var users = this.stats;
            var prices = this.Prices;
            var winners = {}; // id = {totalprice, users} per user = total/nrusers            
            for (var i = 0; i < users.length; i++) {
                var urow = users[i];
                if (!winners[urow.placement]) {
                    winners[urow.placement] = {
                        users: [],
                        total: 0
                    };
                }
                var price = 0;
                if (prices[i])
                    price = prices[i].value;
                winners[urow.placement].total += parseInt(price);
                winners[urow.placement].users.push(this._smallUser(urow));
            }

            this.winners = [];
            for (var pl in winners) {
                var cprice = winners[pl].total / winners[pl].users.length;
                for (i = 0; i < winners[pl].users.length; i++) {
                    var row = {
                        placement: pl,
                        user: winners[pl].users[i],
                        price: cprice,
                        totalpoints: winners[pl].users[i].totalpoints
                    }
                    this.winners.push(row);
                }
            }
        },
        _smallUser(statsrow) {
            return {
                id: statsrow.userid,
                username: statsrow.username,
                totalpoints: statsrow.totalpoints
            }
        },
        useravatar(user) {
            return this.$images.userAvatar(user.id);
        },
        userlink(item) {
            return this.$url.userurl(item);
        },
    },
    mounted() {
        this.$store.dispatch("winners/getWinners").then(() => {
            this.CalculateWinnings();
        });
    },
};
</script>
